<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-8">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-7 col-sm-6">
            <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search label by title...">
              </div>
            </form>
          </div>
          <div class="col-4 col-sm-3">
            <button class="btn btn-primary" @click="showModalLabel = true">Add Label</button>
          </div>
        </div>
        <p class="text-muted mt-3">Labels</p>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Color</th>
                <th colspan="4">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(label, i) in labelList" :key="i" style="cursor: pointer">
                <td>{{ label.title }}</td>
                <td>{{ moreText(label.description) }}</td>
                <td>
                  <figure class="mb-0 mr-2">
                    <div alt="user" :style="'background-color: ' + label.color" style="width: 25px; height: 25px; border-radius: 50%;"/>
                    <!-- <div class="status online"></div> -->
                  </figure>
                </td>
                <td>
                  <div class="d-flex align-items-end">
                    <button class="btn btn-outline-primary btn-sm" @click="showEditLabel(label.id)">
                      <font-awesome icon="edit"/>
                    </button> &nbsp;
                    <button class="btn btn-outline-primary btn-sm" @click="deleteLabel(label.id)">
                      <font-awesome icon="times"/>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty v-if="totalRow == 0" description="No data"></el-empty>
        </div>
        <b-pagination v-if="totalRow > 10" v-model="currentPage" :total-rows="totalRow" :per-page="10"/>
      </div>
    </div>
    <b-modal v-model="showModalLabel" title="Add Label" hide-footer>
      <b-form @submit.prevent="asignUser" @reset="closeModalLabel">
        <b-form-group
          id="user-group"
          :label="$t('general.title')"
          label-for="title">
          <b-form-input
            id="title"
            v-model="v$.formLabel.title.$model"
            :placeholder="$t('labels.placeholder.title')"
            required />
            <b-form-invalid-feedback :state="!v$.formLabel.title.$invalid" v-if="v$.formLabel.title.$errors[0]">
              {{ $t('validation.' + v$.formLabel.title.$errors[0].$validator) }}
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="user-group"
          :label="$t('general.description')"
          label-for="description">
          <b-form-textarea
            id="description"
            v-model="formLabel.description"
            rows="6"
            max-rows="6"
            :placeholder="$t('labels.placeholder.description')"
            required />
        </b-form-group>

        <b-form-group :label="$t('general.color')">
          <el-color-picker v-model="formLabel.color"></el-color-picker>
        </b-form-group>

        <b-form-group
          :label="$t('general.show_in_sidebar')">
          <el-switch
            v-model="formLabel.show_in_sidebar"
            active-color="#13ce66"
            inactive-color="#ff4949" />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <el-button @click="createLabelData" :loading="loading.add_label" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showModalEditLabel" title="Edit Label" hide-footer>
      <b-form @submit.prevent="updateLabelData" @reset="closeModalLabel">
        <b-form-group
          id="user-group"
          :label="$t('general.title')"
          label-for="edit-title">
          <b-form-input
            id="edit-title"
            v-model="selected_label.title"
            :placeholder="$t('labels.placeholder.title')"
            required />
            <b-form-invalid-feedback :state="!v$.selected_label.title.$invalid" v-if="v$.selected_label.title.$errors[0]">
              {{ $t('validation.' + v$.selected_label.title.$errors[0].$validator) }}
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="user-group"
          :label="$t('general.description')"
          label-for="edit-description">
          <b-form-textarea
            id="edit-description"
            v-model="selected_label.description"
            rows="6"
            max-rows="6"
            :placeholder="$t('labels.placeholder.description')"
            required />
        </b-form-group>

        <b-form-group>
          <el-color-picker v-model="selected_label.color"></el-color-picker>
        </b-form-group>

         <b-form-group
          :label="$t('general.show_in_sidebar')">
          <el-switch
            v-model="selected_label.show_in_sidebar"
            active-color="#13ce66"
            inactive-color="#ff4949" />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <el-button @click="updateLabelData" :loading="loading.update_label" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import popupErrorMessages from '@/library/popup-error-messages';
import labelsAPI from '../../../api/labels';

export default {
  name: 'Labels',
  metaInfo: {
    title: 'Labels',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    formLabel: {
      title: {
        required,
      },
    },
    selected_label: {
      title: {
        required,
      },
    },
  },
  data() {
    return {
      loading: {
        update_label: false,
        add_label: false,
      },
      loaderStack: 0,
      loader: null,
      userList: [],
      labelList: [],
      showModalLabel: false,
      showModalEditLabel: false,
      search: '',
      page: 1,
      totalRow: 0,
      formLabel: {
        title: '',
        description: '',
        color: '',
        workspace_id: '',
        show_in_sidebar: false,
      },
      selected_label: {
        id: '',
        title: '',
        description: '',
        color: '',
        workspace_id: '',
        show_in_sidebar: false,
      },
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getLabelList();
      },
    },
    workspaces() {
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.getLabelList();
  },
  methods: {
    moreText(string) {
      return string && string.length > 50 ? `${string.substr(0, 40)}...` : string;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    doSearch() {
      this.page = 1;
      this.getLabelList();
    },
    pageChanged(page) {
      this.page = page;
      this.getLabelList();
    },
    async getLabelList() {
      this.showLoader();
      await labelsAPI.getList(this.activeWorkspace._id, {
        page: this.page,
        per_page: 10,
        search_keyword: this.search,
      }).then(async (res) => {
        await popupErrorMessages(res);
        this.labelList = res.data.rows;
        this.totalRow = res.data.count;
      }).catch(() => {});
      this.hideLoader();
    },
    clearForm() {
      this.v$.formLabel.$reset();
      this.formLabel = {
        title: '',
        description: '',
        color: '',
        workspace_id: '',
        show_in_sidebar: false,
      };
    },
    closeModalLabel() {
      this.showModalLabel = false;
      this.clearForm();
    },
    async deleteLabel(id) {
      this.$confirm(this.$t('labels.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await labelsAPI.delete_label({ id, workspace_id: this.activeWorkspace._id })
              .catch(() => {});
            await popupErrorMessages(response);

            this.$message({
              message: this.$t('labels.success.delete'),
              type: 'success',
            });

            this.page = 1;
            this.search = '';
            this.$parent.$refs.sidebar.getLabelList();
            this.getLabelList();
            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    async updateLabelData() {
      this.v$.selected_label.$touch();
      if (this.v$.selected_label.$error) return;
      this.loading.update_label = true;
      const response = await labelsAPI.update({
        title: this.selected_label.title,
        description: this.selected_label.description,
        color: this.selected_label.color,
        id: this.selected_label.id,
        workspace_id: this.selected_label.workspace_id,
        show_in_sidebar: this.selected_label.show_in_sidebar,
      }).catch(() => {});
      this.loading.update_label = false;
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: this.$t('labels.success.edit'),
      });
      if (this.selected_label.show_in_sidebar) {
        this.$parent.$refs.sidebar.getLabelList();
      }
      this.selected_label = {};
      this.showModalEditLabel = false;
      this.getLabelList();
    },
    async createLabelData() {
      this.v$.formLabel.$touch();
      if (this.v$.formLabel.$error) return;
      this.loading.add_label = true;
      const response = await labelsAPI.create({
        title: this.formLabel.title,
        description: this.formLabel.description,
        color: this.formLabel.color,
        workspace_id: this.activeWorkspace._id,
        show_in_sidebar: this.formLabel.show_in_sidebar,
      }).catch(() => {});
      this.loading.add_label = false;
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: this.$t('labels.success.add'),
      });
      if (this.formLabel.show_in_sidebar) {
        this.$parent.$refs.sidebar.getLabelList();
      }
      this.showModalLabel = false;
      this.loading.add_label = false;
      this.clearForm();
      this.getLabelList();
    },
    showEditLabel(id) {
      this.showModalEditLabel = true;
      this.selected_label = cloneDeep(this.labelList.find((v) => v.id === id));
    },
  },
};
</script>
